import React from 'react'
import { Link } from 'gatsby'

import * as styles from './navigation.module.css'

const Navigation = () => (
  <nav role="navigation" className={styles.container} aria-label="Main">
    <Link to="/" className={styles.logoLink}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="192"
        height="24"
        fill="none"
        viewBox="0 0 681 86"
      >
        <path
          fill="#101320"
          d="M21.48 85.2c9.36 0 14.04-3 16.68-7.2v6h25.56V67.44h-8.16v-46.2H6.12V36.6h32.04v13.8c-2.64-4.2-7.32-7.2-16.68-7.2C6.84 43.2 0 53.76 0 64.2s6.84 21 21.48 21zm6.36-14.04c-7.92 0-10.44-3.24-10.44-6.96 0-3.72 2.52-6.96 10.44-6.96 7.8 0 10.32 3.24 10.32 6.96 0 3.72-2.52 6.96-10.32 6.96zM74.705 0v84h17.4V0h-17.4zM146.465 28.92c-4.32-6.48-10.32-8.88-17.4-8.88-18.12 0-26.76 15-26.76 32.64s8.64 32.52 26.76 32.52c7.08 0 13.08-2.4 17.4-8.88V84h17.4V0h-17.4v28.92zM133.145 69c-9.12 0-13.44-5.16-13.44-16.32 0-11.28 4.32-16.44 13.44-16.44 9 0 13.32 5.16 13.32 16.44 0 11.16-4.32 16.32-13.32 16.32zM204.503 85.2c20.04 0 30.48-13.68 30.48-32.52 0-18.84-10.44-32.64-30.48-32.64s-30.48 13.8-30.48 32.64 10.44 32.52 30.48 32.52zm0-16.2c-9.6 0-13.08-4.32-13.08-16.32s3.48-16.44 13.08-16.44c9.72 0 13.08 4.44 13.08 16.44S214.223 69 204.503 69zM282.393 37.44V84h17.4V37.44h18.84V84h17.4V21.24h-90V84h17.4V37.44h18.96zM380.899 85.2c18.6 0 30.48-7.56 30.48-27.6V21.24h-17.4V54.6c0 10.8-3.36 14.4-13.08 14.4-9.6 0-13.08-3.6-13.08-14.4V21.24h-17.4V57.6c0 20.04 12 27.6 30.48 27.6zM450.332 85.2c18.84 0 28.08-13.32 28.08-27.6h-17.4c-.48 6.48-2.04 11.4-10.56 11.4-10.2 0-11.88-6.12-11.88-16.32s1.68-16.44 11.88-16.44c8.52 0 10.08 4.92 10.56 11.4h17.4c0-14.28-9.24-27.6-28.08-27.6-19.92 0-29.16 13.8-29.16 32.64s9.24 32.52 29.16 32.52zM490.603 0v12h17.4V0h-17.4zm0 21.24V84h17.4V21.24h-17.4zM564.45 37.44V84h17.4V21.24h-59.4V84h17.4V37.44h24.6zM622.511 85.2c20.04 0 30.48-13.68 30.48-32.52 0-18.84-10.44-32.64-30.48-32.64s-30.48 13.8-30.48 32.64 10.44 32.52 30.48 32.52zm0-16.2c-9.6 0-13.08-4.32-13.08-16.32s3.48-16.44 13.08-16.44c9.72 0 13.08 4.44 13.08 16.44S632.231 69 622.511 69zM662.606 67.8V84h17.4V67.8h-17.4z"
        ></path>
      </svg>
    </Link>
    <ul className={styles.navigation}>
      <li className={styles.navigationItem}>
        <Link to="/work/" activeClassName="active">
          work
        </Link>
      </li>
      {/* <li className={styles.navigationItem}>
        <Link to="/about" activeClassName="active">
          about
        </Link>
      </li> */}
    </ul>
  </nav>
)

export default Navigation
